import React, { useEffect, useState } from 'react'
import head from './Img/blue.png'
import flag from './Img/flag.png'
import { MdOutlineVideoLibrary } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import Bhim from './Img/bhim.png'
import { FaTelegram } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineSound } from "react-icons/ai";
import { PiCircleNotchLight } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import BottomBar from './BottomBar';
import { Api_Url } from '../env';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { RiAlertLine } from "react-icons/ri";
import { AiFillAlert } from "react-icons/ai";

const Home = () => {

    const [data, setData] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const GetData = async() =>{
        try {
          const formdata = new FormData();
    formdata.append("token", `${localStorage.getItem('token')}`);
    
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
    
    fetch(`${Api_Url}/api/get-dashboard`, requestOptions)
      .then((response) => response.json())
      .then((result) =>  {
        if(result.status == "001"){
            setData(result.user)
        }
      })
      .catch((error) => console.error(error));
        } catch (error) {
          
        }
      }


 
    
      useEffect(()=>{
        GetData()
        handleShow()
      },[])
  return (
    <div>

        {/* popup start */}
        {/* popup s */}




        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-12 col-sm-12 p-0'>
                    <img src={head} style={{width:'100%'}}/>
                </div>
            </div>


<div className='row' style={{backgroundColor:'#6a6ab3'}}>

    <div className='col-1 pt-1'>
    <AiOutlineSound className='text-white pt-1'/> 
    </div>

    <div className='col-10 pt-2'>
     <p  className='text-white'>Warning !!! Dear New and old Clients ...</p>
    </div>

    <div className='col-1 pt-3 '>
    <PiCircleNotchLight style={{float:'right'}}  className='text-white'/>
    </div>
  
</div>

            <div className='row mt-4 pb-2'>
                <div className='pb-2' style={{display:'flex', justifyContent:'space-between', borderBottom:'0.011px solid #e9e9e9'}}>
                    <h4>Current Token</h4>
                    <h4>{data?.wallet_balance}</h4>
                </div>
            </div>

            <div className='row my-4 pb-5' style={{borderBottom:'0.011px solid #e9e9e9'}}>
               <div className='col-2 mt-1'>
               <img src={flag} style={{width:'100%'}}/>
               </div>
               
               <div className='col-8 p-0'>
                <div style={{lineHeight:'14px',textAlign:'left'}}>
                 <h6 className='mb-0'>IToken</h6>
                 <span style={{fontSize:'12px', fontWeight:'500', color:'#737373'}}>1 Rs = 1 I Token, 1 USDT = 93.5 I Token</span>
            </div>
               </div>
               
               <div className='col-2 mt-1'>
                 <div>
                 <NavLink to="/transaction" style={{textDecoration:'none'}}>
                    <button className='btn btn-primary py-0'>Buy</button>
                    </NavLink>
                 </div>
               </div>
               </div> 
               
        </div>




<div className='container-fluid py-3 mt-2' style={{backgroundColor:'#efefef'}}>
    <div className='row'>
        <div className='col-3'>
       <NavLink to="/tutorial" style={{textDecoration:'none'}}> <MdOutlineVideoLibrary className='text-info ms-3 ' style={{fontSize:'20px'}} />
<h6 className='pt-1' style={{fontSize:'14px', color:'#000'}}>Tutorial</h6></NavLink>
        </div>


        <div className='col-3 '>
      <NavLink to="/sell" style={{listStyle:'none',textDecoration:'none'}}>  <FiEdit className=' ms-1' style={{fontSize:'20px', fontWeight:'300', color:'#3ba2b7'}} />
<h6 className='pt-1' style={{fontSize:'14px', color:'#000 '}}>Sell</h6></NavLink>
        </div>
        
        <div className='col-3'>
          <NavLink to="/kycManage" style={{textDecoration:'none'}}>   <img src={Bhim} className='ms-4' style={{width:'20px'}}/>
 <h6  className='text-nowrap pt-1' style={{fontSize:'14px', color:'#000'}}>Kyc Manage</h6></NavLink>
        </div>
        
        <div className='col-3'>
    {/* <NavLink to="https://t.me/pmwallet1 " target="_blank" style={{textDecoration:'none'}}> */}
    <div onClick={()=>window.location.href="https://t.me/pmwallet1"} >

        <FaTelegram className='ms-4' style={{width:'30px'}}/>
        <h6 className='text-nowrap text-center pt-1' style={{fontSize:'14px', color:'#000'}}>Channel</h6>
    </div>
    {/* </NavLink> */}
</div>

    </div>
</div>


<div className='container-fluid mt-4' style={{borderBottom:'0.011px solid #e9e9e9'}}>
    <div className='row'>
        <div className='col-lg-12'>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <h6 >News</h6>
                <NavLink to="/News" style={{textDecoration:'none'}}> <p style={{color:'#737373'}}>More <IoIosArrowForward/></p></NavLink>

            </div>
        </div>
    </div>
</div>


<div className='container-fluid mt-4'>
    <div className='row'>
        <div className='col-lg-12'>

            <div  >
                <h6> Warning !!! Dear new And Old Clicks...</h6>
                <p style={{fontSize:'12px'}}>2023-08-31  13:55:35</p>
            </div>
        </div>
    </div>
</div>

       

       <div>
        <BottomBar/>
       </div>





       <div> 

      <Modal show={show} onHide={handleClose}>
        
        <Modal.Body>
            
      <div className='row mt-5'>
        <div className='col-lg-12'>

            <h3 style={{color:'red', textAlign:'center', }}><AiFillAlert className='me-3 ' style={{fontSize:'35px'}}/>Notice !</h3>

             <h5 style={{color:'blue', textAlign:'center'}}>Dear new and old clients</h5>
             <h5 style={{color:'red', textAlign:'center'}}><RiAlertLine className='me-2' style={{color:'orange'}} />  please pay correctly to complete the order within 20 minutes. </h5>

             <h5 style={{color:'red', textAlign:'center'}}><RiAlertLine className='me-2' style={{color:'orange'}} />  If you pay for the order after 20 minutes </h5> 
             
             <h5 style={{color:'red', textAlign:'center'}}><RiAlertLine className='me-2' style={{color:'orange'}} />  and the tokens are not received the company will not be responsible for the compensation for this error. </h5>

             
             <h5 className='mt-3' style={{color:'blue', textAlign:'center'}}>Please pay your order in time, thank you for your understanding and support and wish you a happy life every day!</h5>


            
             <div style={{display:'flex', justifyContent:'center'}} >
             <button className='btn fs-4 mt-4 ' style={{color:'blue', textAlign:'center'}}  onClick={handleClose}>Confirm</button>
        </div>
        </div>
    </div>
    
    </Modal.Body> 
      </Modal>
        
       </div>

    </div>
  )
}

export default Home